<template>
  <div>
    <div class="d-inline-block" v-for="(field, iterator) of fields">
      <div :key="'form - ' + iterator">
        {{ iterator === fields.length - 1 ? field.field.label : field.field.label +  ',' }}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      fields: {
        type: Array,
        default: () => []
      }
    },
    created () {
      this.fields.sort(function (a, b) {
        return a.position - b.position;
      });
    }
  }
</script>
